<template>
  <div class="popup position-relative">
    <div class="popup-card">
      <div class="d-flex flex-column align-items-center popup-top-container">
        <button @click="updatePopup(false)" class="btn align-self-end">
          <img class="popup-close" src="../../assets/close@3x.png" alt />
        </button>
        <div class="popup-text text-center">
          <h4>Oops!</h4>
          <p>{{ popupText }}</p>
          <p class="highlight m-0">Leo AR Native Mobile App</p>
        </div>
        <div class="popup-logo">
          <img class src="../../assets/leoLogo.png" alt />
        </div>
      </div>
      <div class="text-center popup-bottom-container">
        <button @click="downloadLeoApp" class="btn w-100 bg-dark p-2 popup-download">
          <img class src="../../assets/transAppleDownload@3x.png" alt />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["popupText"],
  methods: {
    ...mapActions(["updatePopup"]),

    downloadLeoApp() {
      window.location = "https://get.leoapp.com/";
    }
  }
};
</script>


<style>
.popup {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
  margin: auto;
  height: 100vh;
  z-index: 999999;
  background: rgba(0, 0, 0, 0.71);
  padding: 20px;
}

.popup-card {
  flex: 0 0 auto;
  align-self: center;
  z-index: 9999;
  width: 300px;
  background-color: #ffffff;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.5);
  opacity: 0;
  -webkit-animation: fadeIn 100ms linear;
  /* Safari 4+ */
  -moz-animation: fadeIn 100ms linear;
  /* Fx 5+ */
  -o-animation: fadeIn 100ms linear;
  /* Opera 12+ */
  animation: fadeIn 100ms linear;
  /* IE 10+, Fx 29+ */
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.popup-close {
  width: 16px;
}

.popup-top-container {
  background-image: url("../../assets/spaceBackground.jpg");
  background-size: cover;
  background-position: center;
  padding: 10px 10px 0;
}

.popup-bottom-container {
  padding: 40px 20px 20px;
}

.popup-text {
  margin-bottom: -6px;
}

.popup-text h4 {
  font-family: AvenirNext-Demibold;
  font-size: 22px;
  color: #ededed;
}

.popup-text p {
  font-family: AvenirNext-Medium;
  font-size: 17px;
  color: #ededed;
}

.popup-text .highlight {
  font-family: AvenirNext-Demibold;
  font-size: 17px;
  color: #ffffff;
}

.popup-logo {
  position: relative;
  top: 29px;
}

.popup-logo img {
  width: 80px;
  height: 80px;
  background: #ffffff;
  border-radius: 6px;
  padding: 3px;
  margin-left: 1px;
}

.popup-download img {
  height: 26px;
}
</style>
