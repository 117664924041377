<template>
  <div id="object-menu" class="menu card bg-menu" v-show="objectMenu">
    <div class="card-header menu-head">
      <div id="tabs" class="d-flex flex-row overflow-auto">
        <div
          class="tabs align-self-center position-relative m-0 p-0 ml-3 mr-3 text-nowrap align-top"
          v-for="tab in tabs"
          :key="tab.node.id"
        >
          <p :id="tab.node.id" @click="setActiveTabId(tab.node.id)">{{tab.node.name.toUpperCase()}}</p>
        </div>
      </div>
    </div>
    <ScaleLoader
      class="m-auto pt-5"
      v-if="menuObjects.length === 0"
      :loading="menuObjects.length === 0"
      :height="55"
      :width="8"
      :radius="20"
      margin="5px"
      color="#e2e2e2"
    ></ScaleLoader>
    <div id="objects" class="d-flex flex-wrap px-3 pb-3 overflow-auto justify-content-between">
      <div
        v-show="menuObjects.length > 0"
        class="d-flex align-items-center p-2 mt-4"
        v-for="(object,index) of menuObjects"
        :key="index"
      >
        <objectMenuItem :object="object" :setPlace="setPlace"></objectMenuItem>
      </div>
    </div>
  </div>
</template>

<script>
import { ScaleLoader } from "@saeris/vue-spinners";
import objectMenuItem from "./ObjectMenuItem";
export default {
  components: {
    objectMenuItem,
    ScaleLoader
  },

  props: ["menuObjects", "tabs", "objectMenu", "setPlace", "setActiveTabId"],

  mounted() {
    setTimeout(() => {
      document.getElementById("U3lzdGVtVGFnLTMxNQ==").click();
    }, 2000);
  }
};
</script>

<style>
.object-div {
  height: 100px;
}

#tabs p {
  margin: 0;
  padding: 8px 0 13px;
  font-family: Somatic;
  font-size: 11px;
  color: #8f939e;
  letter-spacing: 0.82px;
  line-height: 11px;
  -webkit-transition: color 500ms linear;
  -moz-transition: color 500ms linear;
  -o-transition: color 500ms linear;
  -ms-transition: color 500ms linear;
  transition: color 500ms linear;
}

#tabs p.active-tab,
.menu-name.active-tab {
  color: black;
  -webkit-transition: color 500ms linear;
  -moz-transition: color 500ms linear;
  -o-transition: color 500ms linear;
  -ms-transition: color 500ms linear;
  transition: color 500ms linear;
}

#tabs p.active-tab > .underline {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
  width: 100%;
  border-radius: 4px;
  background-color: #000000;
  opacity: 0;
  -webkit-animation: fadeIn 500ms linear;
  /* Safari 4+ */
  -moz-animation: fadeIn 500ms linear;
  /* Fx 5+ */
  -o-animation: fadeIn 500ms linear;
  /* Opera 12+ */
  animation: fadeIn 500ms linear;
  /* IE 10+, Fx 29+ */
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.underline-main {
  display: block;
  width: calc(100% - 44px);
  height: 3px;
  border-radius: 6px;
  background-color: #000000;
}

#objects > div:nth-of-type(1),
#objects > div:nth-of-type(2),
#objects > div:nth-of-type(3) {
  margin-top: 1rem !important;
}

#objects > div {
  width: 100px;
  height: 100px;
  opacity: 0;
  -webkit-transform: scale(0.95) translateZ(0);
  -moz-transform: scale(0.95);
  -o-transform: scale(0.95);
  /* can stack animations */
  -ms-transform: scale(0.95);
  -webkit-animation: fadeIn 750ms ease;
  /* Safari 4+ */
  -moz-animation: fadeIn 750ms ease;
  /* Fx 5+ */
  -o-animation: fadeIn 750ms ease;
  /* Opera 12+ */
  animation: fadeIn 750ms ease;
  /* IE 10+, Fx 29+ */
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
.tabs {
  color: #959594;
  position: initial;
  margin-top: 6px;
  font-family: Somatic;
  letter-spacing: 0.8pt;
  font-weight: lighter !important;
  font-size: small;
}

.coin {
  position: absolute;
  height: 20px;
  right: 0%;
  bottom: 0%;
}
/* 
.tabs:nth-child(1) > p,
.tabs:nth-child(2) > p {
  color: black !important;
} */
</style>
