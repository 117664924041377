<template>
  <div id="smartBanner" class="d-flex m-2" @click="downloadApp">
    <div class="mx-auto">
      <p class="text-center m-0">
        Download Leo AR App
        <br />To Record a Video
      </p>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    downloadApp() {
      this.$ga.event("Download", "Smart Banner Clicked", window.location.href);
      window.location = "https://get.leoapp.com/";
    }
  }
};
</script>

<style>
#smartBanner p {
  font-family: AvenirNext-DemiBold;
  font-size: 5.3vw;
  line-height: 6.5vw;
  color: white;
}

#smartBanner {
  background-color: black;
  padding: 1.6vw 0;
  border-radius: 10pt;
  box-shadow: 4px -3px 17px 0 rgba(0, 0, 0, 0.5);
}
</style>
