<template>
  <div id="app">
    <Banner v-if="banner" :isSharing="isSharing" :isAddingObject="isAddingObject" />
    <router-view></router-view>
  </div>
</template>

<script>
import Banner from "./components/Banners/Banner.vue";
import { mapState } from "vuex";
export default {
  name: "app",

  components: {
    Banner
  },

  computed: {
    ...mapState(["banner", "isSharing", "isAddingObject"])
  }
};
</script>

<style>
.prompt-box-8w {
  z-index: 999999999999;
}

@font-face {
  font-family: "Somatic";
  src: url("./assets/fonts/Somatic-Rounded.eot");
  src: url("./assets/fonts/Somatic-Rounded.eot?#iefix")
      format("embedded-opentype"),
    /* url('./assets/fonts/Somatic-Rounded.svg#Somatic-Rounded') format('svg'),
        url('./assets/fonts/Somatic-Rounded.ttf') format('truetype'), */
      url("./assets/fonts/Somatic-Rounded.woff") format("woff"),
    url("./assets/fonts/Somatic-Rounded.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SF-Semibold";
  src: url("./assets/fonts/SFUIDisplay-Semibold.eot");
  src: url("./assets/fonts/SFUIDisplay-Semibold.eot?#iefix")
      format("embedded-opentype"),
    /* url('./assets/fonts/SFUIDisplay-Semibold.svg#SFUIDisplay-Semibold') format('svg'),
        url('./assets/fonts/SFUIDisplay-Semibold.ttf') format('truetype'), */
      url("./assets/fonts/SFUIDisplay-Semibold.woff") format("woff"),
    url("./assets/fonts/SFUIDisplay-Semibold.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SF-Regular";
  src: url("./assets/fonts/SFUIDisplay-Regular.eot");
  src: url("./assets/fonts/SFUIDisplay-Regular.eot?#iefix")
      format("embedded-opentype"),
    /* url('./assets/fonts/SFUIDisplay-Regular.svg#SFUIDisplay-Regular') format('svg'),
        url('./assets/fonts/SFUIDisplay-Regular.ttf') format('truetype'), */
      url("./assets/fonts/SFUIDisplay-Regular.woff") format("woff"),
    url("./assets/fonts/SFUIDisplay-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SF-Medium";
  src: url("./assets/fonts/SFUIDisplay-Medium.eot");
  src: url("./assets/fonts/SFUIDisplay-Medium.eot?#iefix")
      format("embedded-opentype"),
    /* url('./assets/fonts/SFUIDisplay-Medium.svg#SFUIDisplay-Medium') format('svg'),
        url('./assets/fonts/SFUIDisplay-Medium.ttf') format('truetype'), */
      url("./assets/fonts/SFUIDisplay-Medium.woff") format("woff"),
    url("./assets/fonts/SFUIDisplay-Medium.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "AvenirNext-DemiBold";
  src: url("./assets/fonts/AvenirNext-DemiBold.eot");
  src: url("./assets/fonts/AvenirNext-DemiBold.eot?#iefix")
      format("embedded-opentype"),
    /* url('./assets/fonts/AvenirNext-DemiBold.svg#AvenirNext-DemiBold') format('svg'),
        url('./assets/fonts/AvenirNext-DemiBold.ttf') format('truetype'), */
      url("./assets/fonts/AvenirNext-DemiBold.woff") format("woff"),
    url("./assets/fonts/AvenirNext-DemiBold.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

* {
  -webkit-overflow-scrolling: touch;
}

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  background: transparent;
}

#logdiv > pre {
  white-space: normal !important;
}

@media screen and (min-device-width: 768px) and (min-width: 768px) {
  #menu {
    max-height: 318px !important;
  }

  #menu.opened {
    min-height: 318px !important;
  }
}
</style>
