<template>
  <div class="exp w-100 align-items-center" :style="{'background-image': `url('${url}')`}">
    <h2>Welcome to</h2>
    <h1 class="text-uppercase">Leo Web AR</h1>
    <p class="txt">
      Step into new worlds using augmented reality
      <br />in your mobile web browser
    </p>
    <div @click="tryIt" class="button-1">View in your camera</div>
    <div @click="download" class="button-2 mt-3"></div>
    <div @click="download" class="button-3 mt-3"></div>
    <div
      @click="$router.push('website'),$router.go()"
      class="d-flex align-items-center m-auto btn-home-page"
    >
      <div class="logo-div">
        <div class="logo"></div>
      </div>Home Page
    </div>
    <div class="powered p-2">
      <img src="../../assets/powered by 8th wall_horizontal_white.png" alt class="h-100" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      url: ""
    };
  },

  computed: {
    ...mapState(["isAndroid"])
  },

  created() {
    this.url = this.isAndroid
      ? "https://res.cloudinary.com/hmqgidtha/image/upload/fl_progressive/a_90/v1568388774/source.webp"
      : "https://res.cloudinary.com/hmqgidtha/image/upload/fl_apng.clip/a_90/v1568388774/source.png";
  },

  methods: {
    ...mapActions(["updateWelcome", "updateIsAndroid"]),

    tryIt() {
      this.$ga.event("AR", "View In Your Camera", window.location.href);
      this.updateIsAndroid(/(android)/i.test(navigator.userAgent));
      this.updateWelcome(false);
      let scene = document.getElementById("scene");
      scene.setAttribute("xrweb", "");
      scene.setAttribute("xrextras-runtime-error", "");
      scene.setAttribute("xrextras-almost-there", "");
    },

    download() {
      this.$ga.event("Splash Screen", "Download");
      window.location = "https://get.leoapp.com/";
    }
  }
};
</script>

<style>
.powered {
  bottom: 0;
  height: 6%;
  width: 100%;
  position: absolute;
  margin: auto;
  margin-right: 0;
  text-align: right;
}

.txt {
  font-family: AvenirNext-DemiBold;
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
  margin: 0;
  margin-top: 6px;
}

.button-1 {
  background-color: rgba(255, 255, 255, 1);
  text-transform: uppercase;
  color: #313131;
  margin-top: 50px;
  padding: 17px 26px;
  text-align: center;
  font-family: AvenirNext-DemiBold;
  font-size: 16px;
  line-height: 16px;
  border-radius: 10px;
  -webkit-animation: shadow-drop-2-center 0.4s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: shadow-drop-2-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation-iteration-count: infinite;
  animation-duration: 1.5s;
  width: 306px;
}

.button-2 {
  background-image: url("../../assets/appStore.png");
  width: 150px;
  height: 50px;
  background-repeat: no-repeat;
  background-size: contain;
  bottom: 140px;
  position: absolute;
}

.button-3 {
  background-image: url("../../assets/googlePlay.png");
  width: 150px;
  height: 50px;
  background-repeat: no-repeat;
  background-size: contain;
  bottom: 80px;
  position: absolute;
}

.btn-home-page {
  cursor: pointer;
  position: absolute;
  top: -3px;
  background-color: rgba(25, 10, 43, 1);
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  font-family: AvenirNext-Medium;
  border-radius: 0 0 20px 20px;
  border: 2px solid grey;
  -webkit-box-shadow: 0px 0px 0px 5px rgba(153, 153, 153, 0.281);
  box-shadow: 0px 0px 0px 5px rgba(153, 153, 153, 0.281);
}

.logo {
  height: 100%;
  background-image: url("../../assets/leo@3x.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
}
.logo-div {
  width: 29px;
  height: 32px;
  margin-right: 12px;
}
</style>
