import Vue from 'vue';
import VueRouter from 'vue-router';
import BootstrapVue from 'bootstrap-vue/dist/bootstrap-vue.esm';
import App from './App.vue';
// import './gesture-detector';
// import './hold-drag';
// import './pinch-scale';
// import './two-finger-spin';
// import './tap-to-place-cursor';
// import './loading';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import store from './Store/index';
import HomePage from './components/HomePage';
import Shared from './components/Shared';
import Sharing from './components/Sharing';
import bugsnag from '@bugsnag/js'
import bugsnagVue from '@bugsnag/plugin-vue'
import VueAnalytics from 'vue-analytics'
import TapAndHold from 'vue-tap-and-hold'
const { detect } = require('detect-browser');

let bugsnagClient = bugsnag('abb918c40b0ab7bc1a0cf607f3221bf3');

bugsnagClient.use(bugsnagVue, Vue)

Vue.use(VueAnalytics, {
  id: 'UA-105101009-2',
  // checkDuplicatedScript: true
  router
})
Vue.use(BootstrapVue);
Vue.use(VueRouter)

Vue.config.ignoredElements = ['a-scene', 'a-sky', 'a-light', 'a-ring', 'a-plane', 'a-camera'];


Vue.use(TapAndHold, {
  holdTime: 2000, // default is 1000
  tapTime: 200 // default is 200
})

const browser = detect();
let isSafari = () => {
  switch (browser && browser.name) {
    case 'safari':
    case 'ios':
      return true;
    default:
      return false;
  }
}

function detectChromeOnIphone() {
  if (
    ( navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) ) && !isSafari()) {
    return true;
  } else {
    return false;
  }
}

function detectmob() {
  
  if (navigator.userAgent.match(/Android/i)
    || navigator.userAgent.match(/webOS/i)
    || navigator.userAgent.match(/iPhone/i)
    || navigator.userAgent.match(/iPad/i)
    || navigator.userAgent.match(/iPod/i)
    || navigator.userAgent.match(/BlackBerry/i)
    || navigator.userAgent.match(/Windows Phone/i)
  ) {
    return true;
  }
  else {
    return false;
  }
}

if (detectChromeOnIphone() && window.location.pathname === '/') {  
  Vue.$ga.event({
    eventCategory: "Redirected",
    eventAction: "On Ios(not supported browser)",
    eventLabel: '-',
    eventValue: '/website'
  });
  window.location.href = '/website'

}

if (!detectmob() && window.location.pathname === '/') {
  Vue.$ga.event({
    eventCategory: "Redirected",
    eventAction: "Not on Mobile",
    eventLabel: '-',
    eventValue: '/website'
  });
  window.location.href = '/website'
}




const routes = [
  { path: '/sharing', component: Sharing },
  { path: '/:id', component: HomePage },
  { path: '/', component: HomePage },
  { path: '/v/:id/', component: Shared }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

Vue.config.productionTip = false;

// Disables WEB AR
if (!window.location.pathname.includes('/v/')) {
  window.location.href = 'https://leoapp.com'
}

// Enable this one to open scene
// new Vue({
//   router,
//   store,
//   el: '#Scene',
//   render: h => h(Scene)
// })



// 



new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')


  