<template>
  <div class="w-100 h-100" @click="clicked">
    <BounceLoader class="m-auto pt-2" :loading="loading" :size="40" color="#e2e2e2"></BounceLoader>
    <div
      v-if="!loading"
      @click="setPlace"
      :id="'menu-'+object.id"
      :style="{'background-image': 'url(' + object.previewImageUrl + ')', 'background-size': 'contain', 'background-repeat': 'no-repeat', 'background-position': 'center'}"
      :class="click ? 'clicked w-100 h-100 d-flex align-items-center border-primary' : 'object-preview w-100 h-100 d-flex align-items-center border-primary' "
    >
      <img v-if="object.glbFileUrl === null" src="../../../assets/leoLogo.png" class="coin" />
    </div>
  </div>
</template>

<script>
import { BounceLoader } from "@saeris/vue-spinners";
export default {
  props: ["object", "setPlace"],

  components: { BounceLoader },

  data() {
    return {
      loading: true,
      click: false
    };
  },

  methods: {
    clicked() {
      this.click = true;
      setTimeout(() => {
        this.click = false;
      }, 2000);
    }
  },

  mounted() {
    this.loading = false;
  }
};
</script>
<style>
.clicked {
  filter: opacity(0.5);
}
</style>
