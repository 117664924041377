<template>
  <div class="video-popup">
    <p class="popup-text">
      Web browser only supports taking photos.
      To record a video and reach more objects
    </p>
    <div class="download">
      <img
        id="dw"
        @click="downloadApp"
        v-if="isAndroid"
        class="download-btn"
        src="../../assets/googlePlay.png"
        alt
      />
      <img @click="downloadApp" v-else class="download-img" src="../../assets/appStore.png" alt />
    </div>
  </div>
</template>


<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      url: ""
    };
  },
  computed: {
    ...mapState(["isAndroid"])
  },

  methods: {
    downloadApp() {
      this.$ga.event("Download", "Video Popup Clicked", window.location.href);
      window.location = "https://get.leoapp.com/";
    }
  }
};
</script>

<style>
.video-popup {
  position: absolute;
  width: 335px;
  height: 75px;
  opacity: 0.88;
  border-radius: 9px;
  background-color: #ffffff;
  bottom: 15% !important;
  left: 0;
  right: 0;
  z-index: 9999999;
  margin: auto;
  display: flex;
  justify-content: center;
  align-content: stretch;
  align-items: baseline;
  opacity: 0;
  -webkit-transform: scale(0.8) translateZ(0);
  -moz-transform: scale(0.8);
  -o-transform: scale(0.8);
  /* can stack animations */
  -ms-transform: scale(0.8);
  -webkit-animation: fadeIn 500ms ease;
  /* Safari 4+ */
  -moz-animation: fadeIn 500ms ease;
  /* Fx 5+ */
  -o-animation: fadeIn 500ms ease;
  /* Opera 12+ */
  animation: fadeIn 500ms ease;
  /* IE 10+, Fx 29+ */
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.popup-text {
  padding-top: 10px;
  width: 80%;
  height: 30%;
  font-family: AvenirNext-Medium;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #2f2f2f;
}

.download {
  position: absolute;
  margin-top: 15%;
}

.download-img {
  width: 35vw;
}
</style>