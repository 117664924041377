<template>
  <div class="w-100">
    <div id="video" class="vid-preview">
      <iframe allowfullscreen frameborder="0" :src="videoUrl" class="vid"></iframe>
      <div class="expbtn">
        <!-- replace this two to open in camera button-->
      <div
          @click="downloadApp()" 
          :style="{'background-image': `url('${url}')`, 'fontSize': '3vw', }"
          class="buttons"
        >Download LEO AR</div>
      </div>
        <!-- <div
          @click="tryIt = true" 
          :style="{'background-image': `url('${url}')`}"
          class="buttons"
        >Experience in your camera</div>
      </div> -->
    <!--  -->
    </div>
    <div v-if="tryIt" class="exp w-100" :style="{'background-image': `url('${url}')`}">
      <h2>Welcome to</h2>
      <h1>Leo Web AR</h1>
      <p
        class="txt"
      >Experience an incredible Web AR adventure without you need the app on your phone</p>
      <div @click="() => {openOnCamera()}" class="button-1">Experience in your camera</div>
      <div @click="tryIt = false" class="backButton">Back To Video</div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapState, mapActions } from "vuex";
export default {
  name: "Shared",
  data() {
    return {
      downloadImg:
        "https://cdn.glitch.com/cc06b883-b59e-469e-9ff4-b5a3a29ad77e%2FdownloadOnTheAppStore%402x.png?v=1566988557872",
      videoUrl: "",
      tryIt: false,
      url: ""
    };
  },

  computed: {
    ...mapState(["activeBackground", "isAndroid"])
  },

  created() {
    this.$ga.event("Shared Page", "Opened", this.$route.params.id);
    this.url = this.isAndroid
      ? "https://res.cloudinary.com/hmqgidtha/image/upload/fl_progressive/a_90/v1568388774/source.webp"
      : "https://res.cloudinary.com/hmqgidtha/image/upload/fl_apng.progressive/a_90/v1568388774/source.png";

    if (this.isAndroid) {
      this.downloadImg =
        "https://cdn.glitch.com/cc06b883-b59e-469e-9ff4-b5a3a29ad77e%2FgooglePlayBadgeUs%402x.png?v=1566994281626";
    }
    axios
      .get(
        "https://v20-leoapp-production.global.ssl.fastly.net/graphql?os=web&unityVersion=2018.1.1f1" +
          "&query=" +
          `
          {
          viewer {
            reality(id: "${this.$route.params.id}"){
              realityMedias{
                edges{
                  node{
                    vimeoId
                  }
                }
              }
            }
          }
      }` +
          "&variables:{}"
      )
      .then(data => {
        this.videoUrl = `https://player.vimeo.com/video/${data.data.data.viewer.reality.realityMedias.edges[0].node.vimeoId}`;
      });
  },

  methods: {
    ...mapActions(["updateObjects", "updateWelcome", "updateShared"]),
    
    //remove this one
    downloadApp() {
      window.location = 'https://get.leoapp.com/';
    },

    openOnCamera() {
      this.updateShared(true);
      axios
        .get(
          "https://v20-leoapp-production.global.ssl.fastly.net/graphql?os=web&unityVersion=2018.1.1f1" +
            " &query=" +
            `
          {
            viewer {
              reality(id: "${this.$route.params.id}"){
                elements
              }
            }
          }` +
            "&variables:{}"
        )
        .then(data => {
          let objects = [];
          data.data.data.viewer.reality.elements.map(element => {
            if (element.style) {
              let id = element.style.id;
              let scale = element.super.scale;
              scale.x = scale.x * 20;
              scale.y = scale.y * 20;
              scale.z = scale.z * 20;
              let position = element.super.position;
              position.x = position.x * 10;
              position.y = position.y * 10;
              position.z = position.z * 10;
              objects.push({
                id: id,
                scale: scale,
                position: position
              });
            }
          });
          this.updateWelcome(false);
          this.updateObjects(objects);
          this.$router.push("/");
        });
    }
  }
};
</script>

<style>
.vid {
  width: 100%;
  height: 100%;
}

.vp-preview {
  background-size: cover;
  background-position: top center;
}

.vid-preview {
  bottom: 0%;
  height: 100%;
  width: 100%;
  z-index: 9999999;
  position: absolute;
  background-color: black;
}

.exp {
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgba(110, 104, 160, 1);
  background-blend-mode: multiply;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 36px;
  padding-right: 36px;
  height: 100% !important;
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 9999999;
  -webkit-animation-name: opacity;
  -webkit-animation-duration: 0.5s;
  animation-name: opacity;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}
#video {
  z-index: 9999;
}
h2 {
  font-family: AvenirNext-DemiBold;
  font-size: 36px;
  line-height: 36px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
  margin: 0;
}
h1 {
  font-family: AvenirNext-DemiBold;
  font-size: 48px;
  line-height: 48px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
  margin: 0;
  margin-top: 4px;
}
.txt {
  font-family: AvenirNext-DemiBold;
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
  margin: 0;
  margin-top: 6px;
}
html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

.expbtn {
  width: 50%;
  height: 2vw;
  margin-left: 30vw;
  position: absolute;
  bottom: 5% !important;
}

.buttons {
  margin: auto !important;
  margin-bottom: 20px;
  padding: 1.7vh 5.2vw;
  border-radius: 10px;
  font-family: AvenirNext-DemiBold;
  font-size: 2vw;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
  text-transform: uppercase;
  position: absolute;
  z-index: 9999;
  background-position: bottom;
  bottom: 0% !important;
  background-color: rgba(110, 104, 160, 0.58);
  background-blend-mode: multiply;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.5), inset 0 0 0 2px #ffffff85;
  -webkit-animation: shadow-drop-2-center 0.4s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: shadow-drop-2-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation-iteration-count: infinite;
  animation-duration: 1.5s;
}

.button-1 {
  content: "Experience in your camera";
  background-color: rgba(255, 255, 255, 1);
  text-transform: uppercase;
  color: #313131;
  margin-top: 4vh;
  padding: 17px 26px;
  text-align: center;
  font-family: AvenirNext-DemiBold;
  font-size: 16px;
  border-radius: 10px;
  -webkit-animation: shadow-drop-2-center 0.4s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: shadow-drop-2-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation-iteration-count: infinite;
  animation-duration: 1.5s;
  width: 306px;
}

.backButton {
  margin-top: 14px;
  font-family: AvenirNext-DemiBold;
  font-size: 16px;
  color: #ffffff;
  letter-spacing: 0;
  text-transform: uppercase;
  text-align: center;
  -webkit-animation: shadow-drop-2-center 0.4s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: shadow-drop-2-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation-iteration-count: infinite;
  animation-duration: 1.5s;
}

@-webkit-keyframes noOpacity {
  to {
    opacity: 0;
  }
}
@keyframes noOpacity {
  to {
    opacity: 0;
  }
}

@-webkit-keyframes opacity {
  to {
    opacity: 1;
  }
}

@keyframes opacity {
  to {
    opacity: 1;
  }
}

@-webkit-keyframes shadow-drop-2-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes shadow-drop-2-center {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
</style>