import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const state = {
  activeBackground: "",
  objects: [],
  banner: true,
  popup: false,
  welcome: true,
  isSharing: false,
  tabs: [],
  shared: false,
  isAndroid: true,
  isAddingObject: false,
  realityId: '',
  image: ''
}
 
const getters = {
}

const mutations = {
  setIsAndroid(state, isAndroidState) {
    state.isAndroid = isAndroidState;
  },

  setActiveBackground(state, Url) {
    state.activeBackground = Url;
  },

  setObjects(state, objectsArray) {
    state.objects = objectsArray;
  },

  addObject(state, object) {
    state.objects.push(object);
  },

  setBanner(state, bannerState) {
    state.banner = bannerState;
  },

  setPopup(state, popupState) {
    state.popup = popupState;
  },

  setWelcome(state, welcomeState) {
    state.welcome = welcomeState;
  },

  setIsSharing(state, isSharingState) {
    state.isSharing = isSharingState;
  },

  setIsAddingObject(state, isAddingObjectState) {
    state.isAddingObject = isAddingObjectState;
  },

  setTabs(state, tabs) {
    state.tabs = tabs;
  },

  setShared(state, shared) {
    state.shared = shared;
  },

  setRealityId(state, realityId) {
    state.realityId = realityId;
  },

  setImage(state, image) {
    state.image = image;
  }
  
}

const actions = {

  updateIsAndroid({ commit }, isAndroidState) {
    commit('setIsAndroid', isAndroidState);
  },

  updateActiveBackground({ commit }, url) {
    commit('setActiveBackground', url);
  },

  updateObjects({ commit }, objectsArray) {
    commit('setObjects', objectsArray);
  },

  updateObjectsAdd({ commit }, object) {
    commit('addObject', object);
  },

  updateBanner({ commit }, bannerState) {
    commit('setBanner', bannerState);
  },

  updatePopup({ commit }, popupState) {
    commit('setPopup', popupState);
  },

  updateWelcome({ commit }, welcomeState) {
    commit('setWelcome', welcomeState);
  },

  updateIsSharing({ commit }, isSharingState) {
    commit('setIsSharing', isSharingState)
  },

  updateIsAddingObject({ commit }, isAddingObjectState) {
    commit('setIsAddingObject', isAddingObjectState)
  },

  updateTabs({ commit }, tabs) {
    commit('setTabs', tabs)
  },

  updateShared({ commit }, shared) {
    commit('setShared', shared)
  },

  updateRealityId({ commit }, realityId) {
    commit('setRealityId', realityId)
  },

  updateImage({ commit }, image) {
    commit('setImage', image)
  }
}

const store = new Vuex.Store({
  state,
  getters,
  mutations,
  actions
});

export default store;
