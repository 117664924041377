<template>
  <div id="banners">
    <SmartBanner v-if="isAddingObject" />
    <ShareBanner v-else-if="isSharing" />
    <RegularBanner v-else />
  </div>
</template>
<script>
import RegularBanner from "./RegularBanner.vue";
import ShareBanner from "./ShareBanner.vue";
import SmartBanner from "./SmartBanner.vue";
export default {
  name: "Banner",
  props: ["isSharing", "isAddingObject"],
  components: {
    RegularBanner,
    ShareBanner,
    SmartBanner
  }
};
</script>

<style>
#banners {
  top: 0%;
  width: 100%;
  position: absolute;
  z-index: 99999;
}

@media screen and (min-device-width: 768px) and (min-width: 768px) {
  #banners {
    top: 0%;
    width: 100%;
    position: absolute;
    z-index: 99999;
  }
  #regularBanner .regularBanner-desc {
    position: relative;
    top: -0.24vw;
    font-family: SF-Regular;
    font-size: 1.14vw;
    color: rgba(50, 50, 50, 0.8);
    letter-spacing: 0.12vw;
  }
  #regularBanner .count {
    font-family: SF-Regular;
    font-size: 35%;
    color: rgba(110, 110, 110, 0.8);
    letter-spacing: 0.1vw;
    text-align: left;
  }
  .dw {
    width: 50%;
  }
  #regularBanner .stars {
    margin-left: 1.2vw;
    width: 30%;
  }
  #regularBanner .regularBanner-title {
    font-family: SF-Regular;
    font-size: 150%;
    color: rgba(0, 0, 0, 0.8);
    letter-spacing: 0.12vw;
  }
  #regularBanner {
    background-color: #f2f2f2;
    -webkit-box-shadow: 0 1px 0 0 #aaaaaa;
    box-shadow: 0 1px 0 1px #aaaaaa;
    padding: 1.38vw 1.41vw;
    border-radius: 0 0 0 10px;
    right: 0%;
    position: absolute;
  }
  #regularBanner .download-btn {
    width: 150px;
  }
  #regularBanner .logo {
    width: 60%;
    margin-right: 0;
  }
}
</style>