<template>
  <div class="screen-container d-flex flex-column justify-content-between">
    <div id="ss" class="position-relative" :style="{'background-image': 'url(' + image + ')'}">
      <div class="download-image" @click="downloadImage"></div>
    </div>
    <div class="share-container">
      <div>
        <div class="share-title">Share with your friends</div>
        <div class="share-text m-md-auto">
          You've created a reality with Leo Web AR. Share it with your friends to get
          all the objects into your friends' camera.
        </div>
      </div>
      <div class="share-items row justify-content-center">
        <div @click="wpShareReality" class="share-item item-1"></div>
        <div @click="msgShareReality" class="share-item item-2"></div>
        <div @click="copyShareReality" class="share-item item-3"></div>
        <div @click="nativeShareReality" class="share-item item-4"></div>
      </div>
    </div>
    <div class="record-ads-banner d-flex justify-content-between m-md-auto">
      <div class="align-self-center text-left">
        <span class="light-text">To rec</span>
        <img src="../assets/record-title-image.png" width="12" height="12" class="record-img" />
        <span class="light-text">rd your</span>
        <pre class="bold-text m-0">world as a video</pre>
      </div>
      <div
        @click="downloadApp"
        :class="isAndroid ? 'long-dw long-dw-android' : 'long-dw long-dw-apple'"
      ></div>
    </div>
    <div id="topPart" @click="this.$router.go(-1)">
      <div id="closeButton" class="m-2"></div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "SharingPage",
  props: ["close"],
  data() {
    return {
      smsBody: ""
    };
  },

  computed: {
    ...mapState(["isAndroid", "realityId", "image"])
  },

  created() {
    let url = window.location.origin + "/" + this.realityId + "/";
    if (this.realityId === "") window.location = url;

    // eslint-disable-next-line no-unused-vars
    window.onpopstate = function(event) {
      let app = document.getElementById("app");
      app.classList.remove("h-100");
    };
  },

  mounted() {
    if (window.location.pathname === "/sharing") {
      let app = document.getElementById("app");
      app.classList.add("h-100");
    }
  },
  methods: {
    ...mapActions["updateBanner"],

    downloadImage() {
      var image = new Image();
      image.src = this.image;
      var w = window.open("");
      w.document.write(image.outerHTML);
    },

    wpShareReality() {
      let url = window.location.origin + "/" + this.realityId + "/";
      let link =
        "https://api.whatsapp.com/send?text=" +
        window.location.origin +
        "/" +
        this.realityId +
        "/";
      window.open(link, "_blank");
      window.location = url;
    },

    msgShareReality() {
      let url = window.location.origin + "/" + this.realityId + "/";
      this.smsBody = this.isAndroid
        ? "sms:&body="
        : "sms:?body=" + window.location.origin + "/" + this.realityId + "/";
      window.open(this.smsBody, "_blank");
      window.location = url;
    },

    copyShareReality() {
      let url = window.location.origin + "/" + this.realityId + "/";
      let temp = document.createElement("textarea");
      let tempMsg = document.createTextNode(url);
      temp.appendChild(tempMsg);
      document.body.appendChild(temp);
      temp.select();
      document.execCommand("copy");
      document.body.removeChild(temp);
      window.location = url;
    },

    nativeShareReality() {
      let url = window.location.origin + "/" + this.realityId + "/";
      if (navigator.share) {
        navigator
          .share({
            title: "Leo Ar Web",
            text: "Check my reality on your camera with Leo AR web - It rocks!",
            url: url
          })
          .then(() => {
            window.location = url;
            this.$ga.event("Sharing", "Share Successful", url);
          })
          .catch(() => {
            this.$ga.event("Sharing", "Share Failed", url);
          });
      }
    },
    downloadApp() {
      this.$ga.event(
        "Download",
        "Sharing Banner Clicked",
        window.location.href
      );
      window.location = "https://get.leoapp.com/";
    }
  }
};
</script>

<style>
#topPart {
  position: absolute;
}

#closeButton {
  position: absolute;
  background-image: url("../assets/close@2x.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 15px;
  height: 15px;
}

.screen-container {
  height: 100%;
  z-index: 99999999;
  position: absolute;
  background-color: white;
}

.share-container {
  margin: 30px 16px;
}

#ss {
  width: 100%;
  height: 100%;
  background-color: lightblue;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.download-image {
  position: absolute;
  bottom: 8px;
  right: 8px;
  background-image: url("../assets/downloadImage.png");
  background-size: contain;
  height: 36px;
  width: 38px;
}

.share-title {
  font-family: AvenirNext-DemiBold;
  font-size: 26px;
  color: #000000;
  letter-spacing: 0;
  text-align: center;
  line-height: 32px;
  margin-bottom: 8px;
}

.share-text {
  font-family: AvenirNext-Medium;
  font-size: 14.5px;
  color: #656565;
  letter-spacing: 0;
  text-align: center;
  line-height: 20px;
  margin-bottom: 12px;
  max-width: 420px;
}

.share-items {
  margin: 20px 6px 0px;
}

.share-item {
  height: 42px;
  width: 42px;
  background-size: contain;
  margin-left: 6px;
  margin-right: 6px;
}

.item-1 {
  background-image: url("../assets/whatsapp-app-icon@2x.png");
}

.item-2 {
  background-image: url("../assets/messages-app-icon@2x.png");
}

.item-3 {
  background-image: url("../assets/copy-link-icon@2x.png");
}

.item-4 {
  background-image: url("../assets/more-icon@2x.png");
}

.record-ads-banner {
  width: calc(100% - 32px);
  height: 100%;
  max-width: 420px;
  max-height: 82px;
  padding: 20px 16px;
  margin: 0 16px 16px;
  -webkit-box-shadow: 0 4px 47px 0 rgba(0, 0, 0, 0.28);
  box-shadow: 0 4px 47px 0 rgba(0, 0, 0, 0.28);
  background-color: #ffffff;
  border-radius: 7px;
}

.long-dw {
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
}

.long-dw-apple {
  width: 118px;
  height: auto;
  background-image: url("../assets/appStore.png");
}

.long-dw-android {
  width: 118px;
  height: auto;
  background-image: url("../assets/googlePlay.png");
}

.light-text {
  font-family: AvenirNext-Regular;
  text-transform: uppercase;
  font-size: 16px;
  color: #000000;
  letter-spacing: 0;
  line-height: 18px;
  white-space: pre-wrap;
}

.bold-text {
  font-family: AvenirNext-Bold;
  text-transform: uppercase;
  font-size: 15px;
  color: #000000;
  letter-spacing: 0;
  line-height: 18px;
  white-space: pre-wrap;
}

.record-img {
  position: relative;
  bottom: 2px;
  margin: 0 0.5px;
  left: 0.5px;
}
</style>