<template>
  <div id="scene-menu" class="menu card obj-menu" v-show="sceneMenu">
    <div id="scenes" class="d-flex flex-wrap justify-content-around px-1 pb-3 overflow-auto">
      <div class="m-1" v-for="(scene,index) in scenes" :key="index" @click="addScene(index)">
        <div
          v-if="index === 0"
          class="d-flex flex-column align-items-center justify-content-center text-center enable-camera-btn"
        >
          <img class="enable-camera-image" src="../../../assets/cameraIcon.png" alt />
          <p
            class="enable-camera-text m-0 mt-2"
          >{{ activeBackground !== "" ? 'Enable Camera' : 'Camera is Enabled' }}</p>
        </div>
        <SceneMenuItem v-else :scene="scene"></SceneMenuItem>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import SceneMenuItem from "./SceneMenuItem";
export default {
  components: {
    SceneMenuItem
  },
  computed: {
    ...mapState(["activeBackground"])
  },
  props: ["scenes", "addScene", "sceneMenu"]
};
</script>

<style>
#scenes > div {
  flex: 1 0 30%;
  margin: 5px;
  height: 130px;
}

.scene-preview {
  height: 130px;
}

.enable-camera-btn {
  height: 130px;
  background-color: rgb(206, 206, 206);
}

.enable-camera-image {
  width: 45px;
}

.enable-camera-text {
  font-family: AvenirNext-DemiBold;
  font-size: 12.16px;
  color: #5e626e;
  letter-spacing: 0;
  text-align: center;
}
</style>