<template>
  <div id="regularBanner" class="d-flex flex-nowrap justify-content-between align-items-center">
    <div>
      <img class="logo" src="../../assets/leoLogo.png" alt />
    </div>
    <div class="flex-fill">
      <div class="d-flex regularBanner-text">
        <span class="regularBanner-title font-weight-bold">Leo AR</span>
        <img class="stars mt-auto mb-auto" src="../../assets/starGroup@2x.png" alt />
        <span class="count">2,45K</span>
      </div>
      <div class="regularBanner-desc">
        <span class>#1 Augmented Reality App</span>
      </div>
    </div>
    <div>
      <img
        id="dw"
        @click="downloadApp"
        v-if="isAndroid"
        class="download-btn"
        src="../../assets/googlePlay.png"
        alt
      />
      <img @click="downloadApp" v-else class="download-btn" src="../../assets/appStore.png" alt />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "regularBanner",
  data() {
    return {};
  },

  computed: {
    ...mapState(["isAndroid"])
  },

  methods: {
    downloadApp() {
      this.$ga.event(
        "Download",
        "Regular Banner Clicked",
        window.location.href
      );
      window.location = "https://get.leoapp.com/";
    }
  }
};
</script>

<style>
.download-btn {
  width: 40.36vw;
}

.count {
  margin-left: 2%;
  margin-top: auto;
  margin-bottom: auto;
  font-size: x-small;
}

#regularBanner {
  background-color: #f2f2f2;
  box-shadow: 0 1px 0 0 #aaaaaa;
  padding: 3.38vw 2.41vw;
}

#regularBanner .regularBanner-text {
  position: relative;
  top: 0.24vw;
}

#regularBanner .regularBanner-title {
  font-family: SF-Regular;
  font-size: 3.7vw;
  color: rgba(0, 0, 0, 0.8);
  letter-spacing: 0.12vw;
}

#regularBanner .regularBanner-desc {
  position: relative;
  top: -0.24vw;
  font-family: SF-Regular;
  font-size: 3.14vw;
  color: rgba(50, 50, 50, 0.8);
  letter-spacing: 0.12vw;
}

#regularBanner .logo {
  width: 8.21vw;
  margin-right: 1.93vw;
}

#regularBanner .download-btn {
  width: 25.36vw;
}

#regularBanner .count {
  font-family: SF-Regular;
  font-size: 2.65vw;
  color: rgba(110, 110, 110, 0.8);
  letter-spacing: 0.1vw;
  text-align: left;
}

#regularBanner .stars {
  margin-left: 1.2vw;
  width: 13.28vw;
}

/* ----------- iPhone 6, 6S, 7 and 8 ----------- */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  #regularBanner {
    background-color: #f2f2f2;
    box-shadow: 0 1px 0 0 #aaaaaa;
    position: absolute;
    top: 0%;
    width: 100%;
    z-index: 99999;
    padding: 3.38vw 2.41vw;
  }
}

/* ----------- iPhone 6+, 7+ and 8+ ----------- */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
  #regularBanner {
    background-color: #f2f2f2;
    box-shadow: 0 1px 0 0 #aaaaaa;
    position: absolute;
    top: 0%;
    width: 100%;
    z-index: 99999;
    padding: 3.38vw 2.41vw;
  }
}
</style>
