<template>
  <div id="shareBanner" class="d-flex m-3" @click="downloadApp">
    <div class="mx-auto">
      <p class="text-center m-0">
        Your friend will see your
        <br />design in 3D
      </p>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    downloadApp() {
      this.$ga.event("Download", "Share Banner Clicked", window.location.href);
      window.location = "https://get.leoapp.com/";
    }
  }
};
</script>

<style>
#shareBanner p {
  font-family: AvenirNext-DemiBold;
  font-size: 5.3vw;
  line-height: 6.5vw;
  color: black;
}

#shareBanner {
  background-color: white;
  padding: 4.6vw 0;
  border-radius: 10pt;
  box-shadow: 4px -3px 17px 0 rgba(0, 0, 0, 0.5);
}
</style>
