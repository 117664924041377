<template>
  <div>
    <WelcomePopup v-show="welcome" />
    <Menu />
  </div>
</template>

<script>
import Menu from "./Menu/Menu";
import WelcomePopup from "./Popups/WelcomePopup";
import { mapState, mapActions } from "vuex";

export default {
  name: "HomePage",
  components: {
    Menu,
    WelcomePopup
  },

  mounted() {
    if (this.$route.params.id !== undefined && !this.isAndroid) {
      this.updateWelcome(false);
      let scene = document.getElementById("scene");
      scene.setAttribute("xrweb", "");
      scene.setAttribute("xrextras-runtime-error", "");
      scene.setAttribute("xrextras-almost-there", "");
    }
  },

  computed: {
    ...mapState([
      "activeBackground",
      "welcome",
      "isSharing",
      "banner",
      "isAndroid"
    ])
  },

  methods: {
    ...mapActions([
      "updateActiveBackground",
      "updateGroundOnTap",
      "updateObjectsAdd",
      "updateWelcome"
    ])
  }
};
</script>

<style>
.option-icon {
  width: 32px;
  margin-bottom: 2px;
}

.fixed-icon {
  width: 45px;
}

.fixed-options {
  top: 10%;
  position: absolute;
  color: white;
  right: 0;
  z-index: 9999999;
  height: 10%;
  width: 45px;
  font-size: 0.5em;
}

#leo-menu {
  border-radius: 10px 10px 0 0;
  position: absolute;
  width: 100%;
  height: 5%;
  bottom: 0;
  z-index: 9999999;
  background-color: white;
  color: dimgray;
  font-size: 1.5rem;
}

#download {
  position: absolute;
  z-index: 9999999;
  width: 100%;
  height: 50%;
  bottom: 0%;
  border-radius: 20px 20px 0 0;
  font-size: medium;
  background-color: lightgray;
  /* display: flex; */
}

.close-dw {
  position: absolute;
  z-index: 99999999;
  right: 10px !important;
  width: 20px;
}

.active {
  text-decoration: underline;
}
</style>
 