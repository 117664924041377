<template>
  <div :class="click ? 'clicked' : false ">
    <div
      v-bind:style="{ backgroundImage: 'url(' + scene + ')' }"
      class="scene-preview"
      style="background-size: cover; background-repeat: no-repeat; background-position: center;"
      @click="clicked"
      alt
    ></div>
  </div>
</template>

<script>
export default {
  props: ["scene"],

  data() {
    return {
      click: false
    };
  },

  methods: {
    clicked() {
      this.click = true;
      setTimeout(() => {
        this.click = false;
      }, 2000);
    }
  }
};
</script>

<style>
.clicked {
  filter: opacity(0.5);
}
</style>
