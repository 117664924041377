<template>
  <div>
    <div
      id="menu-top"
      :class="objectMenu || sceneMenu ? '' : 'hidden' "
      @click="objectMenu = false, sceneMenu = false, updateBanner(true);"
    ></div>
    <div id="menu" :class="objectMenu || sceneMenu ? 'opened' : 'closed' ">
      <div id="menu-head">
        <svg width="100%" height="57px">
          <defs>
            <mask id="mask" x="0" y="-20" width="72" height="72">
              <rect x="0" y="0" width="100%" height="100%" fill="#fff" />
              <circle y cx="50%" cy="17" r="37" />
            </mask>
          </defs>
          <rect
            x="0"
            y="0"
            width="100%"
            height="100%"
            mask="url(#mask)"
            fill="#ffffff"
            rx="14"
            ry="14"
          />
          <rect x="0" y="44" width="100%" height="8" mask="url(#mask)" fill="#ffffff" />
          <rect
            x="0"
            y="49"
            width="100%"
            height="20"
            mask="url(#mask)"
            :fill="objectMenu  ? '#F0F0F0' : '#FFFFFF' "
          />
        </svg>
        <div id="head" class="d-flex justify-content-between" v-show="bottomMenu">
          <div
            class="menu-btn text-center"
            :class="objectMenu || sceneMenu ? 'opened-btn-left' : 'closed-btn-left' "
            @click="objectMenu = true, sceneMenu = false, updateBanner(false), openActiveTab(), updateShared(false), videoPopup = false"
          >
            <span
              :class="objectMenu ? 'menu-name active-tab' : 'menu-name'"
            >{{this.objects.length === 0 ? 'Add Object' : ' Objects' }}</span>
            <span v-if="objectMenu" class="underline-main mx-auto"></span>
          </div>
          <div
            class="menu-btn text-center"
            :class="objectMenu || sceneMenu ? 'opened-btn-right' : 'closed-btn-right' "
            @click="sceneMenu = true, objectMenu = false, updateBanner(false), updateShared(false)"
          >
            <span
              :class="sceneMenu ? 'menu-name active-tab' : 'menu-name'"
            >{{this.activeBackground === "" ? 'Add Scene' : 'Scenes ' }}</span>
            <span v-if="sceneMenu" class="underline-main mx-auto"></span>
          </div>
        </div>
        <div v-tap="openSharing" v-hold="tryVideo" id="shutterButton" class="d-flex camera">
          <HalfCircleSpinner
            v-if="isSharing"
            class="share-icon"
            :animation-duration="1000"
            :size="40"
            color="#000"
          />
          <div id="share-icon-outer">
            <div id="share-icon"></div>
          </div>
        </div>
      </div>
      <!-- object menu  -->
      <ObjectMenu
        :objectMenu="objectMenu"
        :tabs="tabs"
        :menuObjects="menuObjects"
        :setPlace="setPlace"
        :setActiveTabId="setActiveTabId"
      />
      <!-- Scene menu -->
      <SceneMenu :scenes="scenes" :addScene="addScene" :sceneMenu="sceneMenu" />
    </div>
    <Popup v-show="popup" :popupText="popupText" />
    <VideoPopup v-show="videoPopup" />
    <div
      v-if="optionsBtn"
      id="options"
      class="d-flex flex-column align-items-end justify-content-center"
    >
      <div class="d-flex flex-column align-items-center">
        <div id="delete-item" @click="deleteItem">
          <img
            class="option-icon"
            src="https://cdn.glitch.com/cc06b883-b59e-469e-9ff4-b5a3a29ad77e%2Ftrash%402x.png?v=1565172954772"
            alt
          />
          <span>Delete</span>
        </div>
        <div id="duplicate-item" @click="duplicateItem">
          <img
            class="option-icon"
            src="https://cdn.glitch.com/cc06b883-b59e-469e-9ff4-b5a3a29ad77e%2Fduplicate%402x.png?v=1565277102948"
            alt
          />
          <span>Duplicate</span>
        </div>
      </div>
    </div>
    <div class="load-item d-flex flex-column align-items-center">
      <OrbitSpinner v-if="sceneLoading" :animation-duration="1200" :size="55" color="white" />
    </div>
    <div v-show="banner" id="refresh" class="row opened" @click="refresh">
      <div class="refresh-icon col4 m-auto"></div>
      <div id="refresh-text" class="refresh-text col8 text-uppercase m-auto">Refresh Camera</div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState, mapActions } from "vuex";
import * as THREE from "three";
import "swiped-events";
import { OrbitSpinner, HalfCircleSpinner } from "epic-spinners";
import Popup from "../Popups/Popup";
import ObjectMenu from "./ObjectMenu/ObjectMenu.vue";
import SceneMenu from "./SceneMenu/SceneMenu.vue";
import VideoPopup from "../Popups/VideoPopup.vue";
export default {
  components: {
    ObjectMenu,
    SceneMenu,
    Popup,
    OrbitSpinner,
    HalfCircleSpinner,
    VideoPopup
  },

  data() {
    return {
      sceneLoading: false,
      activeTabId: "U3lzdGVtVGFnLTM1MQ==",
      sceneMenu: false,
      videoPopup: false,
      objectMenu: true,
      optionsBtn: false,
      bottomMenu: true,
      selectedItem: "",
      popupText: "This object is only optimized for",
      backgrounds: [
        "",
        "city.jpg",
        "WorldCup_Getty.jpg",
        "mess.jpg",
        "1.jpg",
        "8.jpg",
        "room.jpg",
        "lake2.jpg",
        "beach.jpg",
        "barcelona.jpg",
        "Buro.jpg",
        "7.png",
        "360panorama.jpg",
        "photo-panorama.jpg",
        "panorama-miami-downtown.jpg",
        "panorama-pier.jpg",
        "panorama-view-bridge.jpg",
        "panorama-view-ocean.jpg",
        "panorama-miami.jpg",
        "photo-panorama-view.jpg",
        "panorama-car-dealership.jpg",
        "panorama-rocky-beach.jpg",
        "panorama-view-la.jpg",
        "panorama-view-san-francisco.jpg",
        "panorama-lands-end.jpg"
      ],
      scenes: [],
      menuObjects: [],
      GrapqlURL:
        "https://v20-leoapp-production.global.ssl.fastly.net/graphql?os=web&unityVersion=2018.1.1f1"
    };
  },

  watch: {
    activeTabId: function(val, oldVal) {
      if (val !== "" || oldVal === this.activeTabId) {
        let activeTab = document.getElementById(val);
        activeTab.setAttribute("class", "active-tab");
        let deactiveTab = document.getElementById(oldVal);
        deactiveTab.setAttribute("class", "");
        let span = deactiveTab.getElementsByTagName("span")[0];
        if (span) {
          span.remove();
        }
        this.fillObjects(val);
      }
    }
  },

  computed: {
    ...mapState([
      "activeBackground",
      "objects",
      "popup",
      "tabs",
      "shared",
      "isSharing",
      "realityId",
      "isAddingObject",
      "banner"
    ])
  },

  created() {
    if (this.$route.params.id !== undefined) {
      let webRealityId = window.location.pathname
        .replace("/", "")
        .replace("/", "");
      axios
        .get(
          this.GrapqlURL +
            "&query=" +
            `
          {
            viewer {
              webReality(id: "${webRealityId}") {
                data
              }
            }
          }
          `,
          "&variables:{}"
        )
        .then(data => {
          const shared = data.data.data.viewer.webReality.data;
          this.updateActiveBackground(shared.background_url);
          shared.objects.map(_obj => {
            let obj = JSON.parse(_obj);
            this.putObject(obj.id, obj.position, obj.scale);
          });
        });
    }
  },

  mounted() {
    this.createReality();
    if (this.shared) {
      let scene = document.getElementById("scene");
      scene.setAttribute("xrweb", "");
      scene.setAttribute("xrextras-runtime-error", "");
      scene.setAttribute("xrextras-almost-there", "");

      this.popupText = "Some objects are only optimized for";

      scene.addEventListener("realityready", () => {
        this.updatePopup(true);
      });
    }
    this.getRealityObjects();
    axios
      .get(
        this.GrapqlURL +
          "&query=" +
          `
          {
            viewer {
              homeScreen {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
            }
          }`,
        "&variables:{}"
      )
      .then(data => {
        const edges = data.data.data.viewer.homeScreen.edges;
        this.fillTabs(edges);
      });
    this.fillScenes();
    this.updateBanner(true);
    this.addMenuGestures();
  },

  methods: {
    ...mapActions([
      "updateActiveBackground",
      "updateIsSharing",
      "updateObjects",
      "updateBanner",
      "updatePopup",
      "updateTabs",
      "updateShared",
      "updateObjectsAdd",
      "updateIsAddingObject",
      "updateRealityId",
      "updateImage"
    ]),

    setActiveTabId(activeTabId) {
      this.activeTabId = activeTabId;
    },

    openActiveTab() {
      let activeTab = document.getElementById(this.activeTabId);
      if (activeTab) {
        activeTab.setAttribute("class", "active-tab");
        // let active = document.createElement("span");
        // active.setAttribute("class", "underline");
        // activeTab.appendChild(active);
        this.fillObjects(this.activeTabId);
      }
    },

    getRealityObjects() {
      if (this.objects.length > 0) {
        this.objects.map(object => {
          this.putObject(object.id, object.position, { x: 3, y: 3, z: 3 });
        });
      }
    },

    addScene(id) {
      this.$ga.event("Scene Menu", "Scene Added", id);
      this.sceneLoading = true;
      if (id === 0) {
        this.updateActiveBackground(``);
        this.sceneLoading = false;
      } else {
        this.updateActiveBackground(
          `https://res.cloudinary.com/hmqgidtha/image/upload/fl_progressive:steep/3d-scenes/${this.backgrounds[id]}`
        );

        let sky = document.getElementById("background");
        if (sky) {
          sky.addEventListener("materialtextureloaded", () => {
            this.sceneLoading = false;
          });
        } else {
          setTimeout(() => {
            this.sceneLoading = false;
          }, 3000);
        }
      }
      this.updateBanner(true);
      this.updateReality();
    },

    setPlace(event) {
      let scene = document.getElementById("scene");
      let raycaster = new THREE.Raycaster();
      let camera = document.getElementById("camera");
      let threeCamera = camera.getObject3D("camera");
      let ground = document.getElementById("ground");
      let pos = new THREE.Vector3(0, 0, 0);
      let a = new THREE.Vector2(0, 0);
      threeCamera = threeCamera || camera.getObject3D("camera");
      raycaster.setFromCamera(a, threeCamera);
      const intersects = raycaster.intersectObject(ground.object3D, true);
      let distance = 0;
      if (intersects.length > 0) {
        const intersect = intersects[0];
        distance = intersect.distance;
        pos = intersect.point;
      }
      pos.y = camera.object3D.rotation.y;
      const element = document.createElement("a-gltf-model");
      element.id = "loading";
      element.setAttribute("position", pos);
      element.setAttribute("loading", "");
      element.setAttribute("scale", {
        x: 2 * distance,
        y: 2 * distance,
        z: 2 * distance
      });
      element.setAttribute(
        "src",
        "https://cdn.glitch.com/df08c1eb-32bc-4fe6-842d-bca8bbe0a6df%2Fgem.glb"
      );
      scene.appendChild(element);
      this.addObject(event.target.id, pos, distance);
    },

    fillTabs(tabs) {
      let dance, emojis;
      for (var i = 0; i < tabs.length; i++) {
        if (tabs[i].node.name === "Emojis") {
          emojis = tabs[i];
          tabs.splice(i, 1);
          i--;
        } else if (tabs[i].node.name === "Dance") {
          dance = tabs[i];
          tabs.splice(i, 1);
          i--;
        }
      }
      tabs.unshift(emojis);
      tabs.unshift(dance);
      this.updateTabs(tabs);
      let objectMenu = document.getElementById("objects");
      objectMenu.addEventListener("swiped-left", () => {
        let index = 0;
        this.tabs.map((tab, i) => {
          if (tab.node.id === this.activeTabId) {
            index = i;
          }
        });
        if (index < this.tabs.length - 1) {
          this.activeTabId = this.tabs[index + 1].node.id;
          let elmnt = document.getElementById(this.activeTabId);
          elmnt.scrollIntoView(true);
        }
      });
      objectMenu.addEventListener("swiped-right", () => {
        let index = 0;
        this.tabs.map((tab, i) => {
          if (tab.node.id === this.activeTabId) {
            index = i;
          }
        });
        if (index > 0) {
          this.activeTabId = this.tabs[index - 1].node.id;
          let elmnt = document.getElementById(this.activeTabId);
          elmnt.scrollIntoView(true);
        }
      });
    },

    addMenuGestures() {
      let ground = document.getElementById("ground");
      ground.addEventListener("click", () => {
        this.groundOnTap();
      });

      let menu = document.getElementById("menu-head");

      menu.addEventListener("swiped-right", () => {
        if (this.objectMenu || this.sceneMenu) {
          this.objectMenu = true;
          this.sceneMenu = false;
        }
      });

      menu.addEventListener("swiped-left", () => {
        if (this.objectMenu || this.sceneMenu) {
          this.objectMenu = false;
          this.sceneMenu = true;
        }
      });

      menu.addEventListener("swiped-up", () => {
        if (!this.objectMenu && !this.sceneMenu) {
          this.objectMenu = true;
          this.updateBanner(false);
        }
      });

      menu.addEventListener("swiped-down", () => {
        if (this.objectMenu || this.sceneMenu) {
          this.objectMenu = false;
          this.sceneMenu = false;
          this.updateBanner(true);
        }
      });
    },

    fillScenes() {
      for (let i = 0; i < this.backgrounds.length; i++) {
        this.scenes.push(
          `https://image-cdn.svteknoloji.com/hmqgidtha/image/upload/c_thumb,fl_progressive:steep,h_200,w_200/v1565625557/3d-scenes/${this.backgrounds[i]}`
        );
      }
    },

    async fillObjects(tab) {
      this.$ga.event("Object Menu", "Tab Opened", tab);
      while (this.menuObjects.length) {
        await this.menuObjects.pop();
      }
      axios
        .get(
          this.GrapqlURL +
            "&query=" +
            `
          {
          viewer {
            systemTag(id: "${tab}") {
              models {
                edges {
                  node {
                    id
                    previewImageUrl
                    glbFileUrl
                  }
                }
              }
            }
          }
        }
          `
        )
        .then(data => {
          let availableModels = [];
          let nonAvailableModels = [];

          let models = data.data.data.viewer.systemTag.models;
          for (let i = 0; i < models.edges.length; i++) {
            const model = models.edges[i];
            if (model.node.glbFileUrl !== null) {
              availableModels.push(model);
            } else {
              nonAvailableModels.push(model);
            }
          }
          availableModels.map(model => {
            this.menuObjects.push(model.node);
          });
          nonAvailableModels.map(model => {
            this.menuObjects.push(model.node);
          });
        });
    },

    putObject(id, position, scale) {
      let scene = document.getElementById("scene");
      axios
        .get(
          this.GrapqlURL +
            "&query=" +
            `
            {
              viewer {
                model(id : "${id}"){
                  glbFileUrl
                }
              }
            }` +
            "&variables:{}"
        )
        .then(data => {
          if (data.data.data.viewer.model) {
            let newElement = this.createElement(
              id,
              data.data.data.viewer.model.glbFileUrl,
              position,
              scale
            );
            newElement.setAttribute("visible", "false");
            scene.appendChild(newElement);
            newElement.addEventListener("model-loaded", () => {
              newElement.setAttribute("visible", "true");
            });
          }
        });
    },

    addObject(id, position, distance) {
      axios
        .get(
          this.GrapqlURL +
            "&query=" +
            `
            {
              viewer {
                model(id : "${id}"){
                  glbFileUrl
                  scale
                }
              }
            }` +
            "&variables:{}"
        )
        .then(data => {
          if (this.shared) {
            let scene = document.getElementById("scene");
            let modelLink = null;
            let newElement = null;
            this.updateBanner(true);
            this.objectMenu = false;
            let scale = { x: 2, y: 2, z: 2 };
            modelLink =
              "https://sticker-assets-v2.s3.amazonaws.com/production/td_models/files/87233.glb";
            newElement = this.createElement(id, modelLink, position, scale);
            newElement.setAttribute("visible", "false");
            scene.appendChild(newElement);
            newElement.addEventListener("model-loaded", () => {
              newElement.setAttribute("visible", "true");
            });
          } else {
            if (!data.data.data.viewer.model.glbFileUrl) {
              this.popupText = "This object is only optimized for";
              this.updatePopup(true);
              // this.objectMenu = false;
              let loading = document.getElementById("loading");
              loading.parentNode.removeChild(loading);
              setTimeout(() => {
                this.updatePopup(false);
                this.updateBanner(true);
              }, 5000);
            } else {
              setTimeout(() => {
                let refresh = document.getElementById("refresh");
                let refreshText = document.getElementById("refresh-text");
                refreshText.parentNode.removeChild(refreshText);
                refresh.classList.remove("opened");
                refresh.classList.add("closed");
              }, 2000);
              this.updateIsAddingObject(true);
              let scene = document.getElementById("scene");
              let modelLink = null;
              let newElement = null;
              let x =
                distance > 1
                  ? (data.data.data.viewer.model.scale.x * distance) / 10
                  : data.data.data.viewer.model.scale.x;
              let y =
                distance > 1
                  ? (data.data.data.viewer.model.scale.y * distance) / 10
                  : data.data.data.viewer.model.scale.y;
              let z =
                distance > 1
                  ? (data.data.data.viewer.model.scale.z * distance) / 10
                  : data.data.data.viewer.model.scale.z;
              let scale = { x: x, y: y, z: z };
              this.updateBanner(true);
              // this.objectMenu = false;
              modelLink = data.data.data.viewer.model.glbFileUrl;
              newElement = this.createElement(id, modelLink, position, scale);
              this.updateObjectsAdd({
                id: id,
                scale: scale,
                position: position
              });
              newElement.setAttribute("visible", "false");
              scene.appendChild(newElement);
              newElement.addEventListener("model-loaded", () => {
                new Audio(
                  "https://cdn.glitch.com/cc06b883-b59e-469e-9ff4-b5a3a29ad77e%2FappearanceSound.mp3?v=1575376593247"
                ).play();
                this.updateReality();
                newElement.setAttribute("visible", "true");
                let loading = document.getElementById("loading");
                loading.parentNode.removeChild(loading);
                setTimeout(() => {
                  this.updateIsAddingObject(false);
                }, 5000);
              });
            }
          }
        });
    },

    tryVideo() {
      this.objectMenu = false;
      this.sceneMenu = false;
      this.videoPopup = true;
      setTimeout(() => {
        this.videoPopup = false;
      }, 5000);
    },

    groundOnTap() {
      let ring = document.getElementById("tapRing");
      if (ring) {
        ring.parentNode.removeChild(ring);
      }
      let element = document.getElementById(this.selectedItem);
      if (element) {
        element.setAttribute("pinch-scale", "isActive: false");
        element.setAttribute("two-finger-spin", "isActive: false");
        setTimeout(() => {
          this.selectedItem = "";
        }, 1000);
      }
      this.optionsBtn = false;
    },

    deleteItem() {
      let ring = document.getElementById("tapRing");
      if (ring) {
        ring.parentNode.removeChild(ring);
      }

      const scene = document.getElementById("scene");
      const selected = document.getElementById(this.selectedItem);
      if (this.selectedItem !== null) {
        scene.removeChild(selected);
        this.optionsBtn = false;
        selected.setAttribute("pinch-scale", "isActive: false");
        selected.setAttribute("two-finger-spin", "isActive: false");
        setTimeout(() => {
          this.selectedItem = "";
        }, 1000);
        this.updateReality();
      }
      this.$ga.event("AR based events", "Object Deleted", this.selectedItem);
    },

    duplicateItem() {
      let ring = document.getElementById("tapRing");
      if (ring) {
        ring.parentNode.removeChild(ring);
      }
      const scene = document.getElementById("scene");
      const selected = document.getElementById(this.selectedItem);
      if (this.selectedItem !== null) {
        let position = selected.getAttribute("position");
        let pos_x = position.x + 1;
        let pos_y = position.y + 1;
        let pos_z = position.z + 5;
        const model = selected.getAttribute("gltf-model");
        let scale = selected.getAttribute("scale");
        scene.appendChild(
          this.createElement(
            selected.id,
            model,
            { x: pos_x, y: pos_y, z: pos_z },
            scale
          )
        );
        selected.setAttribute("pinch-scale", "isActive: false");
        selected.setAttribute("two-finger-spin", "isActive: false");
        setTimeout(() => {
          this.selectedItem = "";
        }, 1000);
        this.optionsBtn = false;
        this.updateReality();
      }
      this.$ga.event("AR based events", "Object Duplicated", this.selectedItem);
    },

    options(event) {
      if (!this.optionsBtn) {
        new Audio(
          "https://cdn.glitch.com/cc06b883-b59e-469e-9ff4-b5a3a29ad77e%2FselectionSound.mp3?v=1575376712409"
        ).play();
        this.optionsBtn = true;
        this.selectedItem = event.srcElement.id;
        let element = document.getElementById(this.selectedItem);
        element.setAttribute("pinch-scale", "isActive: true");
        element.setAttribute("two-finger-spin", "isActive: true");
        this.selectedItemPosition = event.srcElement.getAttribute("position");
        let ring = document.getElementById("tapRing");
        if (ring) {
          ring.parentNode.removeChild(ring);
        }
        let object = document.getElementById(event.srcElement.id);
        let selectedObjectScale = object.getAttribute("scale").y;
        let pos = object.getAttribute("position");
        if (!ring) {
          const scene = document.getElementById("scene");
          const tapRing = document.createElement("a-ring");
          tapRing.setAttribute("color", "#e0e0e0");
          tapRing.setAttribute("radius-inner", "-1");
          tapRing.setAttribute("radius-outer", "1");
          tapRing.setAttribute("set-opacity", "0.56");
          tapRing.setAttribute("scale", {
            x: selectedObjectScale,
            y: selectedObjectScale,
            z: selectedObjectScale
          });
          tapRing.setAttribute("rotation", "90 0 0");
          tapRing.setAttribute("position", pos);
          tapRing.id = "tapRing";
          scene.appendChild(tapRing);
        }
      }
    },

    createElement(id, model, position, scale) {
      const camera = document.getElementById("camera");
      const cameraRot = camera.getAttribute("rotation");
      const element = document.createElement("a-entity");
      element.id = id.split("-")[1];
      element.setAttribute("class", "cantap");
      element.setAttribute("rotation", "0 " + cameraRot.y + " 0");
      element.setAttribute("gltf-model", model);
      element.setAttribute("position", position);
      element.setAttribute("scale", scale);
      element.addEventListener("click", () => {
        this.options(event);
      });
      element.setAttribute("hold-drag", "");

      element.setAttribute("pinch-scale", "isActive: false");

      element.setAttribute("two-finger-spin", "isActive: false");

      element.setAttribute("animation-mixer", {
        loop: "repeat"
      });
      this.$ga.event("AR based events", "Object Added", id);
      return element;
    },

    openSharing() {
      const scene = document.getElementById("scene");
      scene.emit("screenshotrequest");
      scene.addEventListener("screenshotready", e => {
        if (!e.detail) {
          return;
        }
        this.updateImage("data:image/jpeg;base64," + e.detail);
        this.$router.push("/sharing");
      });
    },

    updateReality() {
      let share = {
        background_url: "",
        objects: []
      };
      share.background_url =
        this.activeBackground === "" ? "" : this.activeBackground;

      if (this.objects.length > 0) {
        this.objects.map(element => {
          share.objects.push(
            JSON.stringify({
              id: element.id,
              position: element.position,
              scale: element.scale
            })
          );
        });
      }

      axios.post(this.GrapqlURL, {
        query: `
          mutation M {
            updateWebReality(input: {
            webRealityId: "${this.realityId}",
              data: ${JSON.stringify(JSON.stringify(share))}
            }) {
              webReality {
                data
              }
            }
          }
          `
      });
    },

    refresh() {
      this.updateObjects([]);
      let ring = document.getElementById("tapRing");
      if (ring) {
        ring.parentNode.removeChild(ring);
      }
      this.optionsBtn = false;
      const scene = document.getElementById("scene");
      scene.childNodes.forEach(element => {
        if (element.nodeName == "A-ENTITY") {
          let new_element = element.cloneNode(true);
          element.parentNode.replaceChild(new_element, element);
        }
      });
      scene.childNodes.forEach(element => {
        if (element.nodeName == "A-ENTITY") {
          element.parentNode.removeChild(element);
        }
      });
      this.updateReality();
    },

    createReality() {
      let share = {
        background_url: "",
        objects: []
      };
      share.background_url =
        this.activeBackground === "" ? "" : this.activeBackground;

      if (this.objects.length > 0) {
        this.objects.map(element => {
          share.objects.push(
            JSON.stringify({
              id: element.id,
              position: element.position,
              scale: element.scale
            })
          );
        });
      }

      axios
        .post(this.GrapqlURL, {
          query: `
        mutation M {
          createWebReality(input: {data: ${JSON.stringify(
            JSON.stringify(share)
          )} } ) {
            webReality {
              id
            }
          }
        }
      `
        })
        .then(data => {
          this.updateRealityId(data.data.data.createWebReality.webReality.id);
        });
    }
  }
};
</script>


<style>
.share-outer {
  background-color: darkgray;
}

#menu {
  z-index: 999999;
  position: absolute;
  bottom: 0%;
  width: 100%;
  height: auto;
  min-height: 57px;
  max-height: 35.5vh;
  border: none;
  border-radius: 15px 15px 0 0;
  box-shadow: rgba(0, 0, 0, 0.45) 0px -6px 33px 0px;
  -webkit-transition: min-height 300ms ease;
  transition: min-height 300ms ease;
  transition: transform 0.5s ease-out;
}

#menu svg {
  position: absolute;
  z-index: -1;
}

#menu.opened {
  min-height: 35.5vh;
  -webkit-transition: min-height 300ms ease;
  -moz-transition: min-height 300ms ease;
  -o-transition: min-height 300ms ease;
  -ms-transition: min-height 300ms ease;
  transition: min-height 300ms ease;
}

#menu.closed {
  min-height: 57px;
  -webkit-transition: min-height 300ms ease;
  -moz-transition: min-height 300ms ease;
  -o-transition: min-height 300ms ease;
  -ms-transition: min-height 300ms ease;
  transition: min-height 300ms ease;
}

#object-menu,
#scene-menu {
  height: 100%;
  top: 54px;
  padding-bottom: 54px;
}

.menu-name {
  font-family: AvenirNext-DemiBold;
  font-size: 12px;
  color: rgba(103, 107, 120, 0.73);
  letter-spacing: 0;
  text-align: center;
  line-height: 46px;
  text-transform: uppercase;
}

.menu-btn {
  width: calc(50% - 30px);
}

#bottom-menu-close {
  background-color: white;
  border-radius: 15px 15px 0 0;
  width: 100%;
  z-index: 999999;
  height: 10%;
}

.menu-head {
  padding: 0%;
  border: none;
  background-color: #f0f0f0;
}

#menu-top {
  top: 0%;
  height: 60%;
  width: 100%;
  background-color: transparent;
  z-index: 9999;
  position: absolute;
}

.menu {
  position: absolute !important;
  width: 100%;
  bottom: 0;
  z-index: 9999999;
  background-color: white;
  border: none;
}

.share-icon {
  background-position: center;
  background-size: contain;
  width: 30px;
  height: 30px;
  background-image: url("../../assets/shareIcon@2x.png");
  margin: auto;
}

.camera {
  background-color: white;
  width: 60px;
  height: 60px;
  z-index: 99999999;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  margin-top: -13px;
  top: 0;
  left: -0.5px;
  right: 0;
  border-radius: 50%;
  box-shadow: 0.5px 0px 0px 7px rgba(255, 0, 0, 0.4);
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.95) translateZ(0);
    -moz-transform: scale(0.95);
    -o-transform: scale(0.95);
    /* can stack animations */
    -ms-transform: scale(0.95);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1) translateZ(0);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    /* can stack animations */
    -ms-transform: scale(1);
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.95) translateZ(0);
    -moz-transform: scale(0.95);
    -o-transform: scale(0.95);
    /* can stack animations */
    -ms-transform: scale(0.95);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1) translateZ(0);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    /* can stack animations */
    -ms-transform: scale(1);
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.95) translateZ(0);
    -moz-transform: scale(0.95);
    -o-transform: scale(0.95);
    /* can stack animations */
    -ms-transform: scale(0.95);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1) translateZ(0);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    /* can stack animations */
    -ms-transform: scale(1);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.95) translateZ(0);
    -moz-transform: scale(0.95);
    -o-transform: scale(0.95);
    /* can stack animations */
    -ms-transform: scale(0.95);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1) translateZ(0);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    /* can stack animations */
    -ms-transform: scale(1);
  }
}

#options {
  color: white;
  z-index: 99;
  font-family: AvenirNext-Regular;
  font-size: 10px;
  bottom: 10%;
  padding: 10px;
  right: 0;
}

#options > div {
  padding-right: 6px;
  padding-top: 50vh;
}

#options > div > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

#options > div {
  z-index: 999;
}

.load-item {
  z-index: 9;
  position: absolute;
  margin-top: 60%;
  width: 100%;
}

.tabs p:hover,
#shutterButton:hover,
.menu-btn span:hover,
.button-1:hover,
.object-preview:hover {
  cursor: pointer;
}

#share-icon {
  background-image: url("../../assets/shareIcon@2x.png");
  z-index: 999999999;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

#share-icon-outer {
  height: 30px;
  width: 30px;
  margin: auto;
}

#refresh {
  position: absolute;
  left: 5vw;
  top: 20vw;
  z-index: 99999;
  height: 4.5vh;
  border-radius: 10px;
  background-color: #fff;
}

.refresh-icon {
  background-image: url("../../assets/refresh-camera@2x.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 6vw;
  height: 2.7vh;
}

.refresh-text {
  width: 107px;
  height: 20px;
  font-family: AvenirNext-DemiBold;
  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}

.closed {
  width: 10vw;
  -webkit-transition: width 300ms ease;
  -moz-transition: width 300ms ease;
  -o-transition: width 300ms ease;
  -ms-transition: width 300ms ease;
  transition: width 300ms ease;
}

.opened {
  width: 40vw;
  -webkit-transition: width 300ms ease;
  -moz-transition: width 300ms ease;
  -o-transition: width 300ms ease;
  -ms-transition: width 300ms ease;
  transition: width 300ms ease;
}
</style>
